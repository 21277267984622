<template>
  <div class="playbar absolute bottom-0 w-full select-none">
    <div class="playbar-controls w-full bg-transparent flex justify-between items-center text-white">
      <div class="left-side flex items-center" />

      <div class="right-side flex items-center">
        <button
          v-tooltip="'Settings'"
          class="text-white focus:outline-none rounded-full focus:text-gray-800 hover:bg-gray-800 px-4 py-3 focus:bg-white filter-dropshadow"
          @click.prevent
        >
          <fa-icon icon="gear" />
        </button>
        <button
          v-tooltip="viewModeTooltip"
          class="text-white rounded-full focus:outline-none focus:text-gray-800 hover:bg-gray-800 focus:bg-white px-4 py-3 filter-dropshadow"
          @click.prevent="toggleView"
        >
          <fa-icon
            v-if="viewMode === 'narrow'"
            icon="expand-wide"
          />
          <fa-icon
            v-if="viewMode === 'wide'"
            icon="compress-wide"
          />
        </button>
        <button
          v-tooltip="'Fullscreen (f)'"
          class="text-white rounded-full focus:outline-none focus:text-gray-800 hover:bg-gray-800 focus:bg-white px-4 py-3 filter-dropshadow"
          @click.prevent
        >
          <fa-icon icon="expand" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlaybarContainer',

  props: ['viewMode'],

  computed: {
    viewModeTooltip () {
      if (this.viewMode === 'narrow') return 'Theatre mode (t)'
      return 'Narrow mode (t)'
    }
  },

  methods: {
    toggleView () {
      this.$emit('view-mode-toggle')
    }
  }
}
</script>
